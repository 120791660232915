import { startStimulusApp } from '@symfony/stimulus-bridge';
import { onFind } from '@elements/init-modules-in-scope';

export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!../vue/views',
    true,
    /\.(j|t)sx?$/
));

import { registerVueControllerComponents } from '@symfony/ux-vue';
registerVueControllerComponents(require.context('../vue/views', true, /\.vue$/, 'lazy'));

// NEEDED IF YOU USE PINIA

import {createPinia} from 'pinia';
document.addEventListener('vue:before-mount', (event) => {
    const {
        app,
    } = event.detail;

    const pinia = createPinia()
    app.use(pinia);
});


import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as bootstrapExtentions from './bootstrap-extentions';
bootstrapExtentions.init();

import * as removeInitialAnimations from './remove-initial-animation';
removeInitialAnimations.init();

import * as header from './header';
header.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as formValidation from "@elements/form-validation";
formValidation.init();

import * as passwordValidation from "./password-validation";
passwordValidation.init();

import * as actionChanger from "@elements/action-changer";
actionChanger.init();


import * as spriteIcons from './sprite-icons';

// Todo: Add common JS that is shared between all page types here

// import * as renderTemplate from './render-template';
// renderTemplate.init();
//
// if you use this, also use the associated CSS (content-visibility.scss)
// import * as contentVisibility from './content-visibility';
// contentVisibility.init();
