import { onFind } from '@elements/init-modules-in-scope';
import { findIn, toggleClass } from '@elements/dom-utils';

const selectors = {
    header: '.js-header',
    burgerBtn: '.js-header-burger-btn',
}

const classes = {
    isStickyHeader: 'js-is-sticky-header'
}

export function init() {
    onFind(selectors.header, (header) => {
        const burgerBtn = findIn(selectors.burgerBtn, header);

        if(burgerBtn) {
            if(burgerBtn.checked) {
                handleBurgerClick(header);
            }

            burgerBtn.addEventListener('change', () => {
                handleBurgerClick(header);
            });
        }
        addClassOnScroll();
    });


}

function addClassOnScroll() {
    const navbar = document.querySelector('.js-header'); // replace with your navbar selector
    const stickyClass = 'has-background'; // replace with your sticky class

    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            navbar.classList.add(stickyClass);
        } else {
            navbar.classList.remove(stickyClass);
        }
    });
}

function handleBurgerClick(header) {
    toggleClass('is-open', header);
    document.body.classList.toggle('mobile-burger-scroll-disabled');

    if(!header.classList.contains(classes.isStickyHeader)) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
}