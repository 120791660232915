import { onFind } from '@elements/init-modules-in-scope';

import Modal from 'bootstrap/js/dist/modal';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Alert from 'bootstrap/js/dist/alert';
import Tooltip from 'bootstrap/js/dist/tooltip';

export function init() {
    onFind('[data-bs-toggle="modal"]', (modal) => {
        new Modal(modal)
    })

    onFind('[data-bs-toggle="dropdown"]',function (element) {
        new Dropdown(element)
    });

    onFind('.alert', (alert) => {
        // this already handles the dismissing of alerts.

        // for correct styling:
        // it expects the alert markup to be the same as in the alert-notifications elements package or
        // in the alert-dismissable twig template
        new Alert(alert)
    })

    onFind('[data-bs-toggle="tooltip"]', (tooltip) => {
        new Tooltip(tooltip)
    })
}
