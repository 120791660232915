import { onFind } from '@elements/init-modules-in-scope';
import { find, findIn, on, off } from '@elements/dom-utils';

import * as formValidation from '@elements/form-validation';

const selectors = {
    form: '.js-password-validation__form',
    passwordRepeatField: '.js-password-validation__repeat',
    notMatchingMessage: '.js-password-validation__not-matching-message',
    submitButton: '.js-submit-form'
}

const classes = {
    notMatchingMessageHidden: 'password-validation__hidden'
}

export function init() {
    onFind(selectors.form, (form) => {
        let formInstance;
        formValidation.getApi(form).then((formApi) => {
            formInstance = formApi.getFormInstance();
        })

        const passwordRepeatField = find(selectors.passwordRepeatField, form), passwordField = findIn(passwordRepeatField.dataset.passwordValidationEquals, form),
            notMatchingMessage = find(selectors.notMatchingMessage, form), submitButton = findIn(selectors.submitButton, form);

        if(!passwordRepeatField || !passwordField || !notMatchingMessage) {
            console.error('Password validation fields not found!');
            console.log({passwordValidationEquals: passwordRepeatField.dataset.passwordValidationEquals, passwordRepeatField, passwordField, notMatchingMessage});
            return;
        }

        on('input', handlePasswordRepeatInput, passwordField);
        on('input', handlePasswordRepeatInput, passwordRepeatField);
        on('form-validation.submit', handleFormSubmit, form);
        submitButton && on('click', handleFormSubmit, submitButton);

        function handleFormSubmit() {
            validatePassword({
                passwordRepeatField,
                passwordField,
                notMatchingMessage,
                form,
                formInstance,
                handleFormSubmit,
                submitAfterValidation: true
            });
        }
        function handlePasswordRepeatInput() {
            validatePassword({
                passwordRepeatField,
                passwordField,
                notMatchingMessage,
                form,
                formInstance,
                handleFormSubmit,
                submitAfterValidation: false
            });
        }
    })
}


const validatePassword = ({passwordRepeatField, passwordField, notMatchingMessage, form, formInstance, handleFormSubmit, submitAfterValidation = false}) => {
    if (passwordRepeatField.value.length > 0 && passwordField.value.length > 0) {
        const inputsEqual = passwordRepeatField.value === passwordField.value;
        inputsEqual && passwordField.value.length > 0 && passwordRepeatField.value.length > 0 ? notMatchingMessage.classList.add(classes.notMatchingMessageHidden) : notMatchingMessage.classList.remove(classes.notMatchingMessageHidden)

        inputsEqual && submitAfterValidation && formInstance.validate().then((result) => {
            if(result === 'Valid') {
                off("form-validation.submit", handleFormSubmit, form); // prevent infinite submit-loop

                if(form.dataset.action) {
                    form.action = form.dataset.action;
                }

                form.submit()
            }
        })
    }
}