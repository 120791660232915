import { onFind } from '@elements/init-modules-in-scope';
import { removeClass } from '@elements/dom-utils';

const selectors = {
    removeInitialAnimation: '.remove-initial-animation'
}

export function init() {
    onFind(selectors.removeInitialAnimation, (element) => {
        doAsynchronously(() => removeClass('remove-initial-animation', element));
    })
}

function doAsynchronously(callback) {
    setTimeout(() => {
        callback();
    }, 0);
}